:root {
  --box-color: #f5f6f9;
  --app-gradient: linear-gradient(45deg, #a1c4fd, #c2e9fb, #84fab0, #8fd3f4);
  --data: #d9dddf;
  --data-text: #818181;
  --section-color: #fff;
  --active-blue: #1a72dd;
  --line: #164f94;
  --tekst: #a6a6a6;
  --bg-icon: #000;
  --bg-theme: #1a72dd;
}

body.dark-mode{
  --box-color: #1d1d1d;
  --app-gradient: linear-gradient(45deg, #09203f, #537895, #29323c, #485563);
  --data: #095981;
  --line: #164f94;
  --data-text: #818181;
  --section-color: #000;
  --active-blue: #1a72dd;
  --text: #a6a6a6;
  --bg-icon: #fff;
  --bg-theme: #fff;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: var(--app-gradient);
  background-size: 600% 100%;
  animation: Gradient 22s ease infinite;
}

@media (max-width: 991px) {
  .App{
      height: fit-content;
  }
  .App{

  }
}

@keyframes Gradient {
  0% {
      background-position: 0% 50%;
  }

  50% {
      background-position: 100% 50%;
  }

  100% {
      background-position: 0% 50%;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
