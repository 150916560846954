.input-icon svg{
    width: 10%;
    display: inline;
    font-size: 1.5em;
}

.input-icon input{
    width: 90%;
    display: inline;
    margin-bottom: 20px;
}

.contact-btn{
    margin: 20px 0;
    width: 100%;
    padding: 7px 0;
    background: var(--active-blue);
}