.right-sidebar {
    background-color: transparent;
    border-radius: 20px;
    min-height: 600px;
    position: sticky;
    top: 150px;
    width: 120px;
}

.right-icons {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 100%;
    width: 100%;
    min-height: inherit;
}

.right-sidebar svg{
    width: 33px;
    height: 33px;
}

.aside-icon {
    padding: 20px 10px;
    background: var(--section-color);
    border-radius: 15px;
    width: 90%;
    margin: 0 5%;
    text-decoration: none;
}

.active-link {
    background: var(--active-blue);
}

.active-link svg {
    fill: #fff !important;
}

.active-link span {
    color: white !important;
}

.right-icons span {
    display: block;
    font-weight: 300;
    font-size: 12px;
    margin-top: 5px;
    color: var(--bg-icon);
    font-family: sans-serif;
    text-decoration: none;
}