@import url('https://fonts.googleapis.com/css2?family=Caveat&family=Poppins:wght@300;400;500;600&display=swap');

body{
    font-family: 'Poppins', sans-serif;
}

p{
    text-align: left;
    color: var(--text);
}

span{
    color: var(--text);
}

a{
    color: var(--bg-icon);
}

#portfolio-app {
    padding: 50px 0;
    flex-grow: 1;
}

.portfolio-row {
    padding-top: 130px;
}

.title {
    text-align: left;
    font-size: 2em;
    padding-bottom: 20px;
    color: var(--bg-icon);
}

.title::after {

    content: "";
    position: absolute;
    width: 70px;
    height: 1px;
    background-color: var(--active-blue);
    transform: translatey(-50%);
    border-radius: 5px;
    margin: 25px 0 0 30px;
    text-align: left;
}

.cecha {
    padding: 20px;
    border-radius: 15px;
    background-color: var(--box-color);
    margin: 10px 0;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
}

.hidden{
    display: none !important;
}

.active{
    display: block !important;
}

@media (max-width: 1100px) {
    .ml-auto{
        float: right;
    }
    #portfolio-app .aside-icon{
        width: fit-content;
        margin: 5px auto;
        padding: 0.4em 2em;
        background: white;
        color: #737373;
        font-weight: 500;
    }
    #portfolio-app .aside-icon .active-link{
        width: fit-content;
        margin: 5px auto;
        padding: 0.4em 2em;
        background: white;
        color: #737373;
        font-weight: 500;
    }

    #portfolio-app .active-link{
        background-color: #1a72dd;
        color: white;
    }
    #portfolio-app .title{
        font-size: 1.7em;
    }
    .title::after{
        display: none;
    }
}

@media (min-width: 1500px) {
    #portfolio-app {
        padding: 70px 0;
        flex-grow: 1;
    }
    
    .portfolio-row {
        padding-top: 170px;
    }
}