#experience{
    text-align: left;
    width: 100%;
    position: relative;
    display: block;
}

#experience::before{
        content: '';
        position: absolute;
        top: 10px;
        right: 0;
        height: calc(100% - 10px);
        width: 3px;
        background: var(--active-blue);
        opacity: 0.3;
    
}

.exp-item{
    padding: 20px;
    border-radius: 15px;
    background-color: var(--box-color);
    margin: 0 50px 20px 0;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
}  

.exp-header-item{
    display: flex;
    justify-content: space-between;
}

@keyframes pulse {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    50% {
      transform: scale(1.4);
      opacity: 0.7;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }

.exp-item:after {
    height: 20px;
    width: 20px;
    background-color: var(--line);
    content: '';
    border-radius: 50%;  /* Zaokrąglenie wszystkich narożników */
    position: absolute;
    right: -8px;
    animation: pulse 2s infinite;
}

#experience .title::after{
    height: 20px;
    width: 20px;
    background-color: var(--line);
    content: '';
    border-radius: 50%;  /* Zaokrąglenie wszystkich narożników */
    position: absolute;
    right: -8px;
    animation: pulse 2s infinite;
    top: -20px;
}


.exp-item h5{
    font-size: 1.2em;
    margin: 0;
    color: var(--bg-icon);
}

.exp-item p{
    font-size: 1em;
}

.exp-item span{
    font-size: 0.75em;
    font-style: italic;
    margin-bottom: 10px;
    display: block;
}

.data{
    float: right;
    border-radius: 15px;
    font-size: 0.75em;
    padding: 6px 12px;
    background-color: var(--section-color);
    color: var(--bg-icon); 
}

@media (max-width: 1100px) {
    .exp-header-item{
        display: block;
    }
    #experience .data{
        width: 100%;
        font-size: 0.6em;
    }
    .exp-item{
        margin: 0 25px 20px 0;
    }
}