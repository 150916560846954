.logo {
    font-family: 'Caveat', cursive;
    font-size: 2em;
    color: var(--bg-icon);
}

.theme-icon {
    padding: 5px;
    background-color: var(--bg-theme);
    border-radius: 50%;
    width: 45px;
    height: 45px;
    display: inline-block;
    text-align: center;
    line-height: 30px;
}

.theme-icon svg {
    max-width: 70%;
}

span.navbar-toggler-icon.hamburger-span {
    width: 24px;
    height: 24px;
}

#portfolio-app .hamburger{
    background: white;
    margin-right: 40px;
    padding: 10px;;
    border-radius: 30px;
}

.theme-icon {
    margin-right: 20px;
}

@media (min-width: 1200px) {
    .theme-icon {
        margin-right: 100px;
    }
}