#offer h2{
    text-align: left;
    font-size: 1.2em;
    margin-bottom: 0;
    color: var(--bg-icon);
}

#offer .cecha{
    margin: 30px 0;
}

.cecha-naglowek{
    font-size: 0.8em;
    margin-bottom: 10px;
    font-style: italic;
}