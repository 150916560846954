.profile-image {
  width: 230px;
  height: 230px;
  object-fit: cover;
  margin-bottom: 30px;
  margin-top: -150px;
  border-radius: 20px;
}

.left-sidebar {
  width: 350px;
  min-height: 600px;
  border-radius: 20px;
  background-color: var(--section-color);
  text-align: center;
  padding: 40px 20px;
  margin-left: -50px;
  position: -webkit-sticky;
  position: sticky;
  top: 150px;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.1);
}

@media (max-width: 1100px) {
  .left-sidebar{
    width: 90%;
    margin: 0 5%;
  }
  .logo{
    padding-left: 30px;
  }
}

@media (min-width: 1000px) and (max-width: 1100px) {
  .left-sidebar{
    width: 100%;
    margin: 0 10%;
  }
}

.left-sidebar>h1 {
  font-size: 2em;
  color: var(--bg-icon);
}

.left-sidebar span {
  display: block;
  font-size: 14px;
  font-weight: normal;
  padding: 10px 20px;
  margin: 20px 0;
  background-color: var(--box-color);
  border-radius: 5px;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
}

.button-cv{
  margin-top: 30px;
  padding: 14px 33px !important;
  box-shadow: 1px 2px 2cm rgba(0, 0, 0, 0.1);
}

.social-media {
  margin: 30px 15px;
  display: flex;
  justify-content: space-between;
}

.social-media svg {
  display: inline;
  width: 38px;
  margin: 0 10px;
}

.contact-info {
  padding: 50px 10px;
  background: var(--box-color);
  border-radius: 5px;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
}

.contact-label{
  display: flex;
}

.line{
  margin: 20px 0;
  height: 1px;
  background: linear-gradient(to right, transparent, #e3e3e3 0%, #e3e3e3 40%, transparent);
}

.contact-icon svg{
  width: 50px;
  height: 50px;
  text-align: left;
  display: inline;
  margin-left: 15px;
  padding: 10px;
  background: var(--section-color);
  border-radius: 5px;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.1);
}

.contact-text, .contact-icon{
  display: inline;
}

.contact-text p{
  margin: 0;
  text-align: left;
  font-size: 13px;
  padding-left: 10px;
  padding-bottom: 2px;
  color: var(--text);
}

.contact-text a{
  margin: 0;
  text-align: left;
  font-size: 18px;
  padding-left: 10px;
  text-decoration: none;
  color: var(--bg-icon);
  font-weight: 600;
}

