.center-module{
    width: 90%;
    margin: 0 5%;
    background-color: var(--section-color);
    padding: 50px;
    border-radius: 20px;
    min-height: 400px;
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.1);
}

@media (max-width: 991px) {
    .center-module{
        margin: 20px 5%;
    }
}